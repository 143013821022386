jQuery(function($){
    // the sameHeight functions makes all the selected elements of the same height
    if ($(window).width() > 1024) {
        $.fn.sameHeight = function() {
            var selector = this;
            var heights = [];

            // Save the heights of every element into an array
            selector.each(function(){
                var height = $(this).height();
                heights.push(height);
            });

            // Get the biggest height
            var maxHeight = Math.max.apply(null, heights);
            // Show in the console to verify
            // console.log(heights,maxHeight);

            // Set the maxHeight to every selected element
            selector.each(function(){
                $(this).height(maxHeight);
            }); 
        };

        $('.connect').sameHeight();
        $('.flow-content').sameHeight();
        $('.col-gallery').sameHeight();
        $('.leader').sameHeight();
        $('.board').sameHeight();
    }
});


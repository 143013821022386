// const experience = document.querySelector(".heading");
// const experienceSection = document.querySelector("#experience");

// const experienceOptions = {
//     rootMargin: "0px 0px 400px 0px"
// };

// const experienceObserver = new IntersectionObserver(function(
//     entries,
//     experienceObserver
// ) {
//     entries.forEach(entry => {
//         if (!entry.isIntersecting) {
//             experience.classList.add("fixed");
//         } else {
//             experience.classList.remove("fixed");
//         }
//     });
// },
// experienceOptions);

// experienceObserver.observe(experienceSection);